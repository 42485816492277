<template>
  <div>
    <Loading :loadMask="isLoad" />
    <section class="page-wrap" :class="typeClass" ref="contBox">
      <Logo />
      <NavArea />
      <!-- 山海誌 1 動話 -->
      <div class="ani-page ani-page-mountain" v-show="type == 1"></div>
      <!-- 人物誌 2 動話 -->
      <div class="ani-page ani-page-people-0" v-show="type == 2"></div>
      <div class="ani-page ani-page-people-1" v-show="type == 2"></div>
      <div class="ani-page ani-page-people-2" v-show="type == 2"></div>
      <div class="ani-page ani-page-people-3" v-show="type == 2"></div>
      <!-- 食物誌 3 動話 -->
      <div class="ani-page ani-page-food-0" v-show="type == 3"></div>
      <div class="ani-page ani-page-food-1" v-show="type == 3"></div>
      <!-- 植物誌 5 動話 -->
      <div class="ani-page ani-page-plant" v-show="type == 5"></div>
      <!-- 部落誌 6 動話 -->
      <div class="ani-page ani-page-tribe" v-show="type == 6"></div>
      <div></div>
      <div class="cricle">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <PageBanner
        :imgBanner="bannerInfo.imgBanner"
        :tit="bannerInfo.tit"
        :titEn="bannerInfo.titEn"
        :bannerTxt="bannerInfo.bannerTxt"
      />
      <PageCont
        v-for="(item, index) in list"
        :key="'cont' + index"
        :id="'group' + index"
        :tit="item.chinese_name"
        :titEn="item.english_name"
        :videoTit="item.video_title"
        :contImg="item.subcategory_image_url"
        :contInfo="item.subcategory_content"
        :ytImg="item.youtube_url"
        :videoImg="item.image_url"
        :videoInfo="item.video_content"
        :videoPage="typeName"
        :contId="item.subcategory_id"
      />
      <!-- 只有族語誌時才會出現 style="clip-path: none; background: none"-->
      <!-- 如果list 是單數需要補一個 -->
      <div
        v-if="
          typeName == 'Language' && list.length !== 0 && list.length % 2 == 1
        "
      ></div>
      <div
        class="group map-group"
        id="mapGroup"
        style="clip-path: none; background: none"
        v-if="typeName == 'Language'"
      >
        <div class="video-group">
          <div class="p-tit">
            <h2>百工說族語<span>HOME SERIES</span></h2>
          </div>
          <div class="p-video">
            <div class="map-video">
              <iframe
                id="mapVideo"
                :src="mapVideo"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen="allowfullscreen"
              ></iframe>
            </div>
            <h3>愛上部落，從此在這裡紮根</h3>
            <p>
              在這裡，你會看到原本在外的遊子，回到部落，創造自己的故事。在這裡，你會看到來自他國的人們，愛上部落，從此在這裡紮根。在這裡，你會看到這群山海的子民，為了部落，靠雙手努力前進。為了傳遞我們的勇氣，《人物誌》用影像記錄著我們堅毅的身影。
            </p>
          </div>
          <div class="p-video-btn">
            <router-link
              class="map-more"
              :to="{
                name: 'Video',
                params: { id: 'Language' },
                query: { area: mapVideoArea },
              }"
            >
              更多影片
            </router-link>
            <!-- <a class="map-more" href="video_list.html">更多影片</a> -->
          </div>
        </div>
        <div class="map-area">
          <div
            class="map-btn"
            v-for="(item, index) in mapVideoList"
            :key="'mapBtn' + index"
            :class="[item.btn, { act: item.btn == actMb }]"
            @click="mapBtn(item.btn)"
          ></div>
          <!-- <div class="map-btn mb1 act" :class="{ act: actMb == 'mb1' }"></div>
          <div class="map-btn mb2"></div>
          <div class="map-btn mb3"></div>
          <div class="map-btn mb4"></div>
          <div class="map-btn mb5"></div>
          <div class="map-btn mb6"></div>
          <div class="map-btn mb7"></div>
          <div class="map-btn mb8"></div>
          <div class="map-btn mb9"></div>
          <div class="map-btn mb10"></div>
          <div class="map-btn mb11"></div>
          <div class="map-btn mb12"></div>
          <div class="map-btn mb13"></div>
          <div class="map-btn mb14"></div> -->
          <img src="@/assets/img/tw_map.png" alt="" />
        </div>
        <div class="map-clip"></div>
      </div>
      <!-- 只有族語誌時才會出現 End-->
      <PageFooter />
    </section>
  </div>
</template>
<script>
import NavArea from "@/components/NavArea.vue";
import Loading from "@/components/Loading.vue";
import Logo from "@/components/Logo.vue";
import PageBanner from "@/components/PageBanner.vue";
import PageCont from "@/components/PageCont.vue";
import PageFooter from "@/components/PageFooter.vue";

import { apiAreaInfo } from "@/api/api.js";

export default {
  data() {
    return {
      isLoad: true,
      type: "-",
      typeName: "Mountain", //預設第一個 // /Video/Mountain(typeName)?area=1
      typeClass: "page-mountain", //預設第一個
      //=== 標題內容 ===
      bannerInfo: {
        imgBanner: "p_main_img.png", //p_main_img
        tit: "山海誌",
        titEn: "MOUNTAIN AND SEA LOG",
        bannerTxt:
          "在這裡，你會看到原本在外的遊子，回到部落，創造自己的故事。在這裡，你會看到來自他國的人們，愛上部落，從此在這裡紮根。在這裡，你會看到這群山海的子民，為了部落，靠雙手努力前進。為了傳遞我們的勇氣，《人物誌》用影像記錄著我們堅毅的身影。",
      },
      //=== 內頁區塊項目 ===
      list: [
        {
          id: 1,
          chinese_name: "回家系列", //tit
          english_name: "HOME SERIES", //titEn
          video_title: "愛上部落，從此在這裡紮根", //videoTit
          subcategory_image_url: "p_video_img.png", //imgOne p_video_img.png
          //infoOne
          subcategory_content:
            "在這裡，你會看到原本在外的遊子，回到部落，創造自己的故事。在這裡，你會看到來自他國的人們，愛上部落，從此在這裡紮根。在這裡，你會看到這群山海的子民，為了部落，靠雙手努力前進。為了傳遞我們的勇氣，《人物誌》用影像記錄著我們堅毅的身影。",
          youtube_url: "5aPE8Fbxb5g", //imgTwo//yt圖 //p_tag_img.png
          image_url:
            "https://www.kanahei.com/kanaheiwp/wp-content/uploads/2021/03/Ew0xG57VkAMpyv-.jpeg", //自填圖
          //infoTwo
          video_content:
            "在這裡，你會看到原本在外的遊子，回到部落，創造自己的故事。在這裡，你會看到來自他國的人們，愛上部落，從此在這裡紮根。在這裡，你會看到這群山海的子民，為了部落，靠雙手努力前進。為了傳遞我們的勇氣，《人物誌》用影像記錄著我們堅毅的身影。",
        },
        {
          id: 2,
          chinese_name: "回家系列", //tit
          english_name: "HOME SERIES", //titEn
          video_title: "愛上部落，從此在這裡紮根", //videoTit
          subcategory_image_url: "p_video_img.png", //imgOne p_video_img.png
          //infoOne
          subcategory_content:
            "在這裡，你會看到原本在外的遊子，回到部落，創造自己的故事。在這裡，你會看到來自他國的人們，愛上部落，從此在這裡紮根。在這裡，你會看到這群山海的子民，為了部落，靠雙手努力前進。為了傳遞我們的勇氣，《人物誌》用影像記錄著我們堅毅的身影。",
          youtube_url: "5aPE8Fbxb5g", //imgTwo//yt圖 //p_tag_img.png
          image_url:
            "https://www.kanahei.com/kanaheiwp/wp-content/uploads/2021/03/Ew0xG57VkAMpyv-.jpeg", //自填圖
          //infoTwo
          video_content:
            "在這裡，你會看到原本在外的遊子，回到部落，創造自己的故事。在這裡，你會看到來自他國的人們，愛上部落，從此在這裡紮根。在這裡，你會看到這群山海的子民，為了部落，靠雙手努力前進。為了傳遞我們的勇氣，《人物誌》用影像記錄著我們堅毅的身影。",
        },
        {
          id: 3,
          chinese_name: "回家系列", //tit
          english_name: "HOME SERIES", //titEn
          video_title: "愛上部落，從此在這裡紮根", //videoTit
          subcategory_image_url: "p_video_img.png", //imgOne p_video_img.png
          //infoOne
          subcategory_content:
            "在這裡，你會看到原本在外的遊子，回到部落，創造自己的故事。在這裡，你會看到來自他國的人們，愛上部落，從此在這裡紮根。在這裡，你會看到這群山海的子民，為了部落，靠雙手努力前進。為了傳遞我們的勇氣，《人物誌》用影像記錄著我們堅毅的身影。",
          youtube_url: "5aPE8Fbxb5g", //imgTwo//yt圖 //p_tag_img.png
          image_url:
            "https://www.kanahei.com/kanaheiwp/wp-content/uploads/2021/03/Ew0xG57VkAMpyv-.jpeg", //自填圖
          //infoTwo
          video_content:
            "在這裡，你會看到原本在外的遊子，回到部落，創造自己的故事。在這裡，你會看到來自他國的人們，愛上部落，從此在這裡紮根。在這裡，你會看到這群山海的子民，為了部落，靠雙手努力前進。為了傳遞我們的勇氣，《人物誌》用影像記錄著我們堅毅的身影。",
        },
        {
          id: 4,
          chinese_name: "回家系列", //tit
          english_name: "HOME SERIES", //titEn
          video_title: "愛上部落，從此在這裡紮根", //videoTit
          subcategory_image_url: "p_video_img.png", //imgOne p_video_img.png
          //infoOne
          subcategory_content:
            "在這裡，你會看到原本在外的遊子，回到部落，創造自己的故事。在這裡，你會看到來自他國的人們，愛上部落，從此在這裡紮根。在這裡，你會看到這群山海的子民，為了部落，靠雙手努力前進。為了傳遞我們的勇氣，《人物誌》用影像記錄著我們堅毅的身影。",
          youtube_url: "5aPE8Fbxb5g", //imgTwo//yt圖 //p_tag_img.png
          image_url:
            "https://www.kanahei.com/kanaheiwp/wp-content/uploads/2021/03/Ew0xG57VkAMpyv-.jpeg", //自填圖
          //infoTwo
          video_content:
            "在這裡，你會看到原本在外的遊子，回到部落，創造自己的故事。在這裡，你會看到來自他國的人們，愛上部落，從此在這裡紮根。在這裡，你會看到這群山海的子民，為了部落，靠雙手努力前進。為了傳遞我們的勇氣，《人物誌》用影像記錄著我們堅毅的身影。",
        },
      ],
      //=== 族語誌地圖項目 ===
      actMb: "mb1",
      mapVideoArea: "",
      mapVideo: "https://www.youtube.com/embed/5aPE8Fbxb5g",
      mapVideoList: [
        { btn: "mb1", link: "https://www.youtube.com/embed/5aPE8Fbxb5g" }, //宜蘭-南澳
        { btn: "mb2", link: "https://www.youtube.com/embed/O9aW-cQQ5Xw" }, //宜蘭-大同
        { btn: "mb3", link: "https://www.youtube.com/embed/c-PDGPAvp9Q" }, //花蓮-光復 太巴塱
        { btn: "mb4", link: "https://www.youtube.com/embed/yusjI0vW0BU" }, //花蓮-光復 八寶菜湯
        { btn: "mb5", link: "https://www.youtube.com/embed/JY6Jt3DC-sY" }, //花蓮-光復 捏陶
        { btn: "mb6", link: "https://www.youtube.com/embed/hRMvggoOUsU" }, //花蓮-卓溪 立山部落
        { btn: "mb7", link: "https://www.youtube.com/embed/V6xwtayOGsk" }, //花蓮-卓溪 古楓部落
        { btn: "mb8", link: "https://www.youtube.com/embed/r5kNlp7pWI0" }, //花蓮-萬榮 西林 干支部落
        { btn: "mb9", link: "https://www.youtube.com/embed/C2LuH5inlTg" }, //花蓮-萬榮 馬遠部落
        { btn: "mb10", link: "https://www.youtube.com/embed/02UBF-eR39s" }, //花蓮-瑞穗 馬立雲部落
        { btn: "mb11", link: "https://www.youtube.com/embed/aZsgF8hsRhs" }, //花蓮-壽豐 池南部落
        { btn: "mb12", link: "https://www.youtube.com/embed/zJ7CsCfEt_E" }, //花蓮-秀林 銅門部落
        { btn: "mb13", link: "https://www.youtube.com/embed/eWJdSWwt-TY" }, //花蓮-豐濱 靜浦部落
        { btn: "mb14", link: "https://www.youtube.com/embed/cBWX1sdD8TQ" }, //台東-卑南 初鹿部落
        { btn: "mb15", link: "https://www.youtube.com/embed/OO3p2Ory0qg" }, //台東-卑南 東興村 達魯瑪克
        { btn: "mb16", link: "https://www.youtube.com/embed/sOuGx-15DDI" }, //台東-南王部落
        { btn: "mb17", link: "https://www.youtube.com/embed/QdTK6XBkAMU" }, //台東-建和部落
        { btn: "mb18", link: "https://www.youtube.com/embed/3XnqvnjqsjY" }, //台東-黃昏市場
        { btn: "mb19", link: "https://www.youtube.com/embed/Im46F0lK7Wo" }, //台東-知本部落
        { btn: "mb20", link: "https://www.youtube.com/embed/vRmRbCHqq24" }, //台東-長濱 樟原部落
        { btn: "mb21", link: "https://www.youtube.com/embed/rteDTyarvLM" }, //台東-長濱 永福部落
        { btn: "mb22", link: "https://www.youtube.com/embed/HXSuh4ZJNAE" }, //台東-鹿野 巴拉雅拜部落
        { btn: "mb23", link: "https://www.youtube.com/embed/8BqrpvSCsUI" }, //台東-鹿野 嶺榴部落
        { btn: "mb24", link: "https://www.youtube.com/embed/VY5p_c7hevk" }, //台東-蘭嶼 東清部落
        { btn: "mb25", link: "https://www.youtube.com/embed/lMyzB3h6ITw" }, //台東-延平 桃園部落
        { btn: "mb26", link: "https://www.youtube.com/embed/jzO6r7CVUCY" }, //高雄-茂林 萬山部落
        { btn: "mb27", link: "https://www.youtube.com/embed/6X1rBo0AYTQ" }, //高雄-茂林 萬山部落-羊角鉤
        { btn: "mb28", link: "https://www.youtube.com/embed/pAEq9Li21YM" }, //高雄-茂林 茂林部落
        { btn: "mb29", link: "https://www.youtube.com/embed/Au_oNsbIVHg" }, //高雄-茂林 多納里多納部落
        { btn: "mb30", link: "https://www.youtube.com/embed/-RWwq-X8oPc" }, //高雄-桃園 桃園區
        { btn: "mb31", link: "https://www.youtube.com/embed/NCmIbrY_4ec" }, //高雄-桃園 高中部落
        { btn: "mb32", link: "https://www.youtube.com/embed/uZf6FrTVYnE" }, //高雄-那瑪夏 達卡努瓦部落
        { btn: "mb33", link: "https://www.youtube.com/embed/jcqH2F7G4ss" }, //屏東-安坡部落
        { btn: "mb34", link: "https://www.youtube.com/embed/hv6Xoxvf3Vs" }, //屏東-三地門 鄉口社村
        { btn: "mb35", link: "https://www.youtube.com/embed/gOiwPbsMuxM" }, //屏東-霧台 霧台部落
        { btn: "mb36", link: "https://www.youtube.com/embed/4LDLg_vXHfM" }, //屏東-霧台 好茶村
        { btn: "mb37", link: "https://www.youtube.com/embed/GNbQfpf6_44" }, //屏東-霧台 文樂部落
        { btn: "mb38", link: "https://www.youtube.com/embed/QvdOUvcWWjU" }, //屏東-霧台 來義部落
        { btn: "mb39", link: "https://www.youtube.com/embed/A6GTx-Fi-ms" }, //屏東-牡丹鄉 石門村大梅部落
        { btn: "mb40", link: "https://www.youtube.com/embed/q00hQBDM5IE" }, //屏東-瑪家鄉 佳義村
        { btn: "mb41", link: "https://www.youtube.com/embed/Zs6mGcJRwsM" }, //南投-仁愛 新生村眉原部落
        { btn: "mb42", link: "https://www.youtube.com/embed/lA3NgyCzyVU" }, //南投-仁愛 南豐村眉溪部落
        { btn: "mb43", link: "https://www.youtube.com/embed/NgA5Vb6iizA" }, //南投-仁愛 春陽部落
        { btn: "mb44", link: "https://www.youtube.com/embed/tvNMgx9mf3g" }, //南投-仁愛 親愛部落
        { btn: "mb45", link: "https://www.youtube.com/embed/DTwiVPLOA0U" }, //南投-仁愛 法治村武界部落
        { btn: "mb46", link: "https://www.youtube.com/embed/aIy7t0rP_jU" }, //南投-魚池鄉 日月潭伊達卲部落
        { btn: "mb47", link: "https://www.youtube.com/embed/v6IH60HV9J8" }, //南投-信義鄉 潭南部落
        { btn: "mb48", link: "https://www.youtube.com/embed/tWJ4v-9mBhs" }, //苗栗-泰安鄉 錦水村達比拉斯部落
        { btn: "mb49", link: "https://www.youtube.com/embed/4feRfNSbtiY" }, //苗栗-南庄 東河部落
        { btn: "mb50", link: "https://www.youtube.com/embed/gENOXs8Q-aY" }, //嘉義-阿里山 達邦部落
        { btn: "mb51", link: "https://www.youtube.com/embed/XDh8lCGtzNQ" }, //新竹-五峰鄉 花園部落
      ],
    };
  },
  mounted() {
    window.navClick();
    //=== 取得分類 ===
    var typeUrl = this.$route.params.id;
    this.typeLoad(typeUrl);
    //=== 關閉Load ===
    this.isLoad = false;
    //=== 圖片更新完才來跑動畫 (有可能BANNER圖先跑或後跑完)===
    this.$bus.$on("updateBannerImg", (data) => {
      // console.log("updateBannerImg", data);
      this.aniPage();
    });
    this.$bus.$on("updateImg", (data) => {
      // console.log("updateImg", data);
      var listL = data.replace("group", "");
      this.bannerImg = data;
      if (this.list.length - 1 == listL) {
        this.aniPage();
      }
    });
  },
  methods: {
    //=== 讀取頁面類別資料 ===
    typeLoad(typeUrl) {
      //== 影片連結分類位置 ==
      this.typeName = typeUrl;
      //== 分類標題 ==
      switch (typeUrl) {
        case "Mountain":
          this.type = 1;
          this.typeClass = "page-mountain";
          this.bannerInfo.tit = "山海誌";
          this.bannerInfo.titEn = "HOME";
          //== 滾動動畫 ==
          // window.mountainPageAnimate();
          break;
        case "People":
          this.type = 2;
          this.typeClass = "page-red";
          this.bannerInfo.tit = "人物誌";
          this.bannerInfo.titEn = "PEOPLE";
          //== 滾動動畫 ==
          // window.peoplePageAnimate();
          break;
        case "Food":
          this.type = 3;
          this.typeClass = "page-people";
          this.bannerInfo.tit = "美食誌";
          this.bannerInfo.titEn = "FOOD";
          //== 滾動動畫 ==
          // window.foodPageAnimate();
          break;
        case "Language":
          this.type = 4;
          this.typeClass = "page-lanuage";
          this.bannerInfo.tit = "族語誌";
          this.bannerInfo.titEn = "LANGUAGE";
          break;
        case "Plant":
          this.type = 5;
          this.typeClass = "page-plant";
          this.bannerInfo.tit = "植物誌";
          this.bannerInfo.titEn = "PLANT";
          //== 滾動動畫 ==
          // window.plantPageAnimate();
          break;
        case "Tribe":
          this.type = 6;
          this.typeClass = "page-tribe";
          this.bannerInfo.tit = "部落誌";
          this.bannerInfo.titEn = "COMMUNITY";
          //== 滾動動畫 ==
          // window.tribePageAnimate();
          break;
        default:
          console.log("如果都不符合，回首頁");
          this.$router.push("/");
      }
      this.getApiAreaInfo();
    },
    //=== 滾動動畫 ===
    aniPage() {
      // var group0 = this.$refs.group0;
      // // var groupTop0 = group0.offsetTop;
      var group0 = this.$("#group0");
      var group1 = this.$("#group1");
      var group2 = this.$("#group2");
      var group3 = this.$("#group3");
      var groupTop0, groupTop1, groupTop2, groupTop3;
      // console.log(group0);.position()
      group0.is(":visible")
        ? (groupTop0 = this.$("#group0").offset().top - 150)
        : "";
      group1.is(":visible")
        ? (groupTop1 = this.$("#group1").offset().top - 150)
        : "";
      group2.is(":visible")
        ? (groupTop2 = this.$("#group2").offset().top - 150)
        : "";
      // console.log(groupTop2);
      group3.is(":visible")
        ? (groupTop3 = this.$("#group3").offset().top - 150)
        : "";
      console.log(groupTop0, groupTop1, groupTop2, groupTop3);

      switch (this.type) {
        case 1:
          //== 山海誌 ==
          this.$(".ani-page-mountain").css("top", groupTop0);
          window.mountainPageAnimate();
          break;
        case 2:
          //== 人物誌 ==
          this.$(".ani-page-people-0").css("top", groupTop0);
          group1.is(":visible")
            ? this.$(".ani-page-people-1").css("top", groupTop1)
            : this.$(".ani-page-people-1").css("top", "-1000px");
          group2.is(":visible")
            ? this.$(".ani-page-people-2").css("top", groupTop2)
            : this.$(".ani-page-people-2").css("top", "-1000px");
          group3.is(":visible")
            ? this.$(".ani-page-people-3").css("top", groupTop3)
            : this.$(".ani-page-people-3").css("top", "-1000px");
          window.peoplePageAnimate();
          break;
        case 3:
          //== 美食誌 ==
          this.$(".ani-page-food-0").css("top", groupTop0);
          console.log(group1.is(":visible"));
          group1.is(":visible")
            ? this.$(".ani-page-food-1").css("top", groupTop1)
            : this.$(".ani-page-food-1").css("top", "-1000px");
          window.foodPageAnimate();
          break;
        case 5:
          //== 植物誌 ==
          this.$(".ani-page-plant").css("top", groupTop0);
          window.plantPageAnimate();
          break;
        case 6:
          //== 部落誌 ==
          this.$(".ani-page-tribe").css("top", groupTop0);
          window.tribePageAnimate();
          break;
        default:
          console.log("都不符合，不執行動畫");
      }
    },
    //=== 取得資料 Api ===
    getApiAreaInfo() {
      apiAreaInfo({ type: this.type })
        .then((result) => {
          // console.log(result.data);
          if (result.data.data.length == 0) {
            // console.log(result.data.data);
            alert("此資料不存在或已刪除。");
            // this.$router.push("/AreaList/" + this.typeUrl);
          } else {
            // console.log(result.data.data);
            var {
              image_url,
              image_content,
              subcategory_list,
            } = result.data.data;
            this.bannerInfo.imgBanner = image_url;
            this.bannerInfo.bannerTxt = image_content;
            this.list = subcategory_list;
            if (this.type == 4) {
              this.mapVideoArea = this.list[0].subcategory_id;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 族語誌地圖點擊 ===
    mapBtn(val) {
      var mapInd = this.mapVideoList.findIndex((item) => {
        return item.btn == val;
      });
      var { btn, link } = this.mapVideoList[mapInd];
      this.actMb = btn;
      this.mapVideo = link;
    },
    //=== 回到最上 ===
    goTop() {
      // this.$(".page-wrap").stop().animate({ scrollTop: 0 }, 0);
      this.$refs.contBox.scrollTop = 0;
    },
    //操作dom
    // aniDomGet(typeUrl) {
    //   // console.log(typeUrl);
    //   let s = -1;
    //   if (typeUrl == "People") {
    //     //console.log("我是People");
    //     document.querySelectorAll(".group").forEach(function (node) {
    //       s = s + 1;
    //       let positionT = node.offsetTop - 150;
    //       console.log(s, positionT);
    //       let aniTarget = document.querySelector(".ani-page-people-" + s);
    //       // console.log(aniTarget);
    //       aniTarget.style.top = positionT + "px";
    //     });
    //   } else if (typeUrl == "Food") {
    //     //console.log("我是food");
    //     document.querySelectorAll(".group").forEach(function (node) {
    //       s = s + 1;
    //       let positionT = node.offsetTop - 150;
    //       console.log(s, positionT);
    //       let aniTarget = document.querySelector(".ani-page-food-" + s);
    //       // console.log(aniTarget);
    //       aniTarget.style.top = positionT + "px";
    //     });
    //   }
    //   console.log("取得group完成");
    // },
  },
  updated() {
    //=== 取得.group數量位置 把位置塞給動畫 ===
    // var typeUrl = this.$route.params.id;
    // this.aniDomGet(typeUrl);
    // this.$nextTick(function () {
    //   // DOM updated
    //   this.aniPage();
    // });
  },
  beforeDestroy() {
    //==== 元件銷毀前要取消監聽 ====
    this.$bus.$off("updateBannerImg");
    this.$bus.$off("updateImg");
  },
  watch: {
    $route() {
      var nowType = this.$route.params.id;
      console.log("== 更換項目 ==");
      this.goTop();
      this.typeLoad(nowType);
    },
  },
  components: {
    NavArea,
    Loading,
    Logo,
    PageBanner,
    PageCont,
    PageFooter,
  },
};
</script>
