<template>
  <div class="group-main">
    <div class="p-main-img">
      <img
        :src="imgBanner ? imgBanner : require('@/assets/img/img_default.png')"
        :alt="tit"
        @load="onImgBannerLoad"
      />
      <!-- require('@/assets/img/' + imgBanner)" -->
    </div>
    <div class="p-tit">
      <h2>
        {{ tit }}<span>{{ titEn }}</span>
      </h2>
    </div>
    <div class="p-txt">
      {{ bannerTxt }}
    </div>
  </div>
</template>
<script>
export default {
  name: "PageBanner",
  props: ["imgBanner", "tit", "titEn", "bannerTxt"],
  methods: {
    //=== 當圖片更新好後通知跑動畫 ===
    onImgBannerLoad() {
      this.$bus.$emit("updateBannerImg", true);
    },
  },
};
</script>