var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:_vm.id,staticClass:"group",attrs:{"id":_vm.id}},[_c('div',{staticClass:"video-group"},[_c('div',{staticClass:"p-tit"},[_c('h2',[_vm._v(" "+_vm._s(_vm.tit)),_c('span',[_vm._v(_vm._s(_vm.titEn))])])]),(!_vm.videoTit)?_c('div',{staticStyle:{"color":"#000","position":"relative","margin":"25px 0 0"}},[_vm._v(" 尚無影片。 ")]):_vm._e(),_c('router-link',{staticClass:"p-video",attrs:{"to":{
        name: 'Video',
        params: { id: _vm.videoPage },
        query: { area: _vm.contId },
      }}},[(_vm.ytImg)?_c('img',{attrs:{"src":_vm.ytImg
            ? 'http://img.youtube.com/vi/' + _vm.ytImg + '/sddefault.jpg'
            : require('@/assets/img/img_default.png'),"alt":_vm.videoTit}}):_c('img',{attrs:{"src":_vm.videoImg,"alt":_vm.videoTit}}),_c('h3',[_vm._v(_vm._s(_vm.videoTit))]),_c('p',[_vm._v(" "+_vm._s(_vm.videoInfo)+" ")])])],1),_c('div',{staticClass:"p-img"},[_c('img',{attrs:{"src":_vm.contImg ? _vm.contImg : require('@/assets/img/img_default.png'),"alt":_vm.tit},on:{"load":_vm.onImgLoad}}),_c('p',[_vm._v(" "+_vm._s(_vm.contInfo)+" ")]),_c('router-link',{attrs:{"to":{
        name: 'Video',
        params: { id: _vm.videoPage },
        query: { area: _vm.contId },
      }}},[_vm._v("更多影片 ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }