<template>
  <div class="group" :id="id" :ref="id">
    <div class="video-group">
      <div class="p-tit">
        <h2>
          {{ tit }}<span>{{ titEn }}</span>
        </h2>
      </div>
      <div
        v-if="!videoTit"
        style="color: #000; position: relative; margin: 25px 0 0"
      >
        尚無影片。
      </div>
      <router-link
        :to="{
          name: 'Video',
          params: { id: videoPage },
          query: { area: contId },
        }"
        class="p-video"
      >
        <img
          :src="
            ytImg
              ? 'http://img.youtube.com/vi/' + ytImg + '/sddefault.jpg'
              : require('@/assets/img/img_default.png')
          "
          :alt="videoTit"
          v-if="ytImg"
        />
        <img :src="videoImg" :alt="videoTit" v-else />
        <!-- 如果有 yt 抓 yt 沒有抓 video url -->
        <!-- http://img.youtube.com/vi/xxxxxxx/sddefault.jpg -->
        <h3>{{ videoTit }}</h3>
        <p>
          {{ videoInfo }}
        </p>
      </router-link>
    </div>
    <div class="p-img">
      <img
        :src="contImg ? contImg : require('@/assets/img/img_default.png')"
        :alt="tit"
        @load="onImgLoad"
      />
      <!-- <img :src="require('@/assets/img/' + contImg)" alt="" /> -->
      <p>
        {{ contInfo }}
      </p>
      <router-link
        :to="{
          name: 'Video',
          params: { id: videoPage },
          query: { area: contId },
        }"
        >更多影片
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: "PageCont",
  props: [
    "tit",
    "titEn",
    "videoTit",
    "contImg",
    "contInfo",
    "ytImg",
    "videoImg",
    "videoInfo",
    "videoPage",
    "contId",
    "id",
  ],
  mounted() {
    // var groupTop0 = this.$refs["group0"].offsetTop;
    // console.log("內部", groupTop0);
  },
  methods: {
    //=== 當圖片更新好後通知跑動畫 ===
    onImgLoad() {
      this.$bus.$emit("updateImg", this.id);
    },
  },
};
</script>
